import React from "react";
import "./styles.css";
import { Button } from "react-bootstrap";
import { RedirectPage } from "../../Utils";
import lang from "../../Utils/lang";
import imaage404 from "../../Assets/gallery/dsc00052b.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ErrorPage = () => {
  const back = () => {
    RedirectPage("");
  };
  return (
    <div className="container-top-image-error">
      <LazyLoadImage width={"100%"} src={imaage404} />
      {/* <img width="100%" src={imaage404}></img> */}
      <div className="text-block">
        <h1>404</h1>
        <h2>{lang.errorTitle}</h2>
        <Button
          style={{
            backgroundColor: "#04b8cf",
            borderColor: "#04b8cf",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          onClick={() => back()}
        >
          {lang.button404}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
