import React from "react";
import "./styles.css";
import FormElement from "../../Componants/FormElement";
import { Button } from "react-bootstrap";
import { enumFileDownload } from "../../Enum";
import { downloadFile } from "../../Utils";
import lang from "../../Utils/lang";
import bookingBg from "../../Assets/images/contactPhoto.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Booking = () => {
  document.title = lang.metaTitleBooking;
  const download = (file) => {
    if (file === enumFileDownload.STAGE) {
      downloadFile(
        require("../../Assets/downloadFile/blue.pdf"),
        "InscriptionBlueFormule.pdf"
      );
    } else if (file === enumFileDownload.CLUB) {
      downloadFile(
        require("../../Assets/downloadFile/club.pdf"),
        "InscriptionFormuleClub.pdf"
      );
    }
  };

  return (
    <div>
      <div className="container-booking-bg">
        {/* <LazyLoadImage
          width={"100%"}
          className="bg-booking"
          src={
            "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317320/samples/surf/contactPhoto_be7xdt.jpg"
          }
        /> */}
        <img className="bg-booking" src={bookingBg} />
      </div>
      <div className="container-booking">
        <span className="section-title">{lang.BookingTitlePage}</span>
        <span className="section-description">{lang.BookingPresentation}</span>
        <FormElement />
      </div>
      <div className="section-booking-download">
        <span className="section-title">{lang.bookingForm}</span>
        <span className="text-registration">{lang.PlanProgramMini}</span>
        <Button
          onClick={() => download(enumFileDownload.STAGE)}
          variant="dark"
          className="button-download"
        >
          {lang.download}
        </Button>
        <span className="text-registration">{lang.PlanClubMini}</span>
        <Button
          onClick={() => download(enumFileDownload.CLUB)}
          variant="dark"
          className="button-download"
        >
          {lang.download}
        </Button>
      </div>
    </div>
  );
};
export default Booking;
