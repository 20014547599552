import React from "react";
import "./styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface Props {
  icon: any;
  socialName: string;
  link: string;
}

const SocialElement = (props: Props) => {
  const { icon, socialName, link } = props;

  return (
    <div className="container-social">
      <div>
        <LazyLoadImage
          width="auto"
          height="35px"
          style={{ marginRight: "10px" }}
          src={icon}
        />
        {/* <img
          width="auto"
          height="35px"
          className="social-link-image"
          src={icon}
        ></img> */}
      </div>
      <div>
        <a
          className="social-link-name"
          href={link}
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          {socialName}
        </a>
      </div>
    </div>
  );
};

export default SocialElement;
