import * as emailjs from "emailjs-com";
import lang from "./lang";

export const sendEmail = (Message) => {
  const templateId = process.env.REACT_APP_TEMPLATE;

  sendFeedback(templateId, {
    message_html: Message,
  });
};

const sendFeedback = (templateId, variables) => {
  //@ts-ignore
  emailjs.init(process.env.REACT_APP_SECRET_EMAIL);
  emailjs
    .send("gmail", templateId, variables)
    .then((res) => {
      window.alert("Votre Email à bien été envoyé  :)");
    })

    .catch((err) =>
      window.alert(
        `Echec :( ! l'envoi de l'email à échoué veuillez nous contacter par mail : ${lang.email} ou téléphone : ${lang.phoneNumber}`
      )
    );
};

export const templeteHtmlDemande = (
  firstName: string,
  name: string,
  message: string,
  email: string,
  numerPhone: string
) => {
  return `<div style="vertical-align: middle; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);padding:20px;text-align: center;">
    <h1>Demande de ${name} ${firstName}</h1>
    <p>
     ${message}
    </p>
    <h3>coordonnées emetteur</h3>
    <h5>Email : ${email} </h5>
    <h5>Tel : ${numerPhone}</h5>
  </div>`;
};

export const templeteHtmlContact = (
  message: string,
  email: string,
  numerPhone: string
) => {
  return `<div style="vertical-align: middle; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);padding:20px;text-align: center;">
  <h1> ${email} vous a contacté</h1>
  <p>
   ${message}
  </p>
  <h3>coordonnées emetteur</h3>
  <h5>Email : ${email} </h5>
  <h5>Tel : ${numerPhone}</h5>
</div>`;
};

export const RedirectPage = (page) => {
  window.location.href = `${window.location.protocol}//${window.location.host}/${page}`;
};

export const downloadFile = (file, name: string) => {
  let link = document.createElement("a");
  link.href = file;
  link.download = name;
  link.dispatchEvent(new MouseEvent("click"));
};
