import React, { useState, useRef, useEffect } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import logo from "../../Assets/icones/logoBlue.png";
import "./styles.css";
import { enumLang } from "../../Enum";
import strings from "../../Utils/lang";
import frenchFlag from "../../Assets/icones/france.png";
import ukFlag from "../../Assets/icones/uk.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Topbar = () => {
  const ref: any = useRef(null);
  const [top, setTop] = useState(0);
  const [height, setHeight] = useState(0);
  const [addStyle, setAddStyle] = useState("container-root");

  const handleScroll = () => {
    const content: any = document.getElementById("content-body");
    const sticky = ref.current.offsetTop;
    const spaceH = ref.current.offsetHeight;
    if (spaceH > 0) {
      setHeight(spaceH);
    }
    if (sticky > 0) {
      setTop(sticky);
    }
    if (top > 0 && height > 0) {
      if (window.pageYOffset > top) {
        setAddStyle("container-root sticky");
        content.style.paddingTop = `${height}px`;
      } else {
        setAddStyle("container-root");
        content.style.paddingTop = "0px";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const changeLang = (lang) => {
    const asyncLocalStorage = {
      setItem: function (key, value) {
        return Promise.resolve().then(function () {
          localStorage.setItem(key, value);
        });
      },
    };
    switch (lang) {
      case enumLang.FR:
        asyncLocalStorage.setItem("lang", enumLang.FR).then(() => {
          window.location.href =
            window.location.pathname +
            window.location.search +
            window.location.hash;
        });
        break;
      case enumLang.EN:
        asyncLocalStorage.setItem("lang", enumLang.EN).then(() => {
          window.location.href =
            window.location.pathname +
            window.location.search +
            window.location.hash;
        });
        break;
    }
  };

  const getIconLang = () => {
    const lang = localStorage.getItem("lang");
    switch (lang) {
      case enumLang.FR:
        return frenchFlag;

      case enumLang.EN:
        return ukFlag;

      default:
        return frenchFlag;
    }
  };

  return (
    <div>
      <div
        style={{
          paddingTop: "30px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a style={{ textDecoration: "none" }} href="/">
          <div style={{ color: "black", fontSize: 30 }}>Blue Surf School</div>
        </a>
        <div className="line" />
      </div>
      <div id="navbarTop" ref={ref} className={addStyle}>
        <div className="container-top-bar">
          {/* pixel de l'icon pour le width */}
          <div style={{ width: "75px" }}>
            <a href="/">
              <img height="60px" className="imageLogo" src={logo} />
            </a>
          </div>
          <div className="line-tab">
            <Nav className="justify-content-center" activeKey="/home">
              <Nav.Item>
                <Nav.Link href="/Ecole-de-surf-Longeville-sur-mer">
                  {strings.schoolTitle}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/Tarif-ecole-de-surf-Vendee-Longeville">
                  {strings.planTitle}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/Booking">{strings.bookingTitle}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/Contact">{strings.contactTitle}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/Gallery">{strings.galleryTitle}</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="dropdown-tab">
            <Nav>
              <NavDropdown id="" title="Menu">
                <NavDropdown.Item href="/Ecole-de-surf-Longeville-sur-mer">
                  {strings.schoolTitle}
                </NavDropdown.Item>
                <NavDropdown.Item href="/Tarif-ecole-de-surf-Vendee-Longeville">
                  {strings.planTitle}
                </NavDropdown.Item>
                <NavDropdown.Item href="/Booking">
                  {strings.bookingTitle}
                </NavDropdown.Item>
                <NavDropdown.Item href="/Contact">
                  {strings.contactTitle}
                </NavDropdown.Item>
                <NavDropdown.Item href="/Gallery">
                  {strings.galleryTitle}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
          <div style={{ color: "white" }}>
            <Nav>
              <NavDropdown
                id=""
                title={
                  <img
                    className="thumbnail-image"
                    width="35px"
                    src={getIconLang()}
                  />
                }
              >
                <NavDropdown.Item
                  id="fr"
                  onClick={(e) => changeLang(e.target.id)}
                >
                  <LazyLoadImage
                    style={{ paddingRight: "5px" }}
                    width="35px"
                    src={frenchFlag}
                  />
                  {/* <img
                    style={{ paddingRight: "5px" }}
                    width="35px"
                    src={frenchFlag}
                  /> */}
                  Francais
                </NavDropdown.Item>
                <NavDropdown.Item
                  id="en"
                  onClick={(e) => changeLang(e.target.id)}
                >
                  <LazyLoadImage
                    style={{ paddingRight: "5px" }}
                    width="35px"
                    src={ukFlag}
                  />
                  {/* <img
                    style={{ paddingRight: "5px" }}
                    width="35px"
                    src={ukFlag}
                  /> */}
                  English
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </div>
        <div className="line-ombre" />
      </div>
    </div>
  );
};

export default Topbar;
