import React from "react";
// import team from "../../Assets/icones/team-leader.png";
// import surf from "../../Assets/icones/surf.png";
// import umbrella from "../../Assets/icones/umbrella.png";
import image from "../../Utils/images";
import "./styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ChartreElement = () => {
  return (
    <div>
      <div className="school-charte">
        <div className="Chartre-quality-text">
          <LazyLoadImage width="250px" height="auto" src={image.teamLeader} />
          {/* <img src={image.teamLeader} width="250px" height="auto" /> */}
        </div>
        <div className="Chartre-quality-text">
          <LazyLoadImage width="250px" height="auto" src={image.surf} />
          {/* <img src={image.surf} width="250px" height="auto" /> */}
        </div>
        <div className="Chartre-quality-text">
          <LazyLoadImage width="250px" height="auto" src={image.umbrella} />
          {/* <img src={image.umbrella} width="250px" height="auto" /> */}
        </div>
      </div>
    </div>
  );
};

export default ChartreElement;
