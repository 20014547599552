import React from "react";
import bg from "../../Assets/images/home.jpg";
import "./styles.css";
import lang from "../../Utils/lang";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
const TopBackground = () => {
  return (
    <div className="container-top-image">
      <LazyLoadImage
        className="background-image-zoom"
        width={"100%"}
        height={"100%"}
        src={
          "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317131/samples/surf/home_e6jkww.jpg"
        }
      />
      {/* <img
        className="background-image-zoom"
        src={
          "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317131/samples/surf/home_e6jkww.jpg"
        }
      ></img> */}
      <div className="text-block-home">
        <h1>{lang.Homeh1}</h1>
        <h2>{lang.Homeh2}</h2>
        <h3>{lang.Homeh3}</h3>
      </div>
    </div>
  );
};

export default TopBackground;
