import React from "react";
import "./styles.css";
import CardImage from "../../Componants/CardImage";
import school1 from "../../Assets/images/School1.jpg";
import school2 from "../../Assets/images/Team.jpg";
import shcool3 from "../../Assets/images/School3.jpg";
import lang from "../../Utils/lang";
import ChartreElement from "../../Componants/ChartreElement";
import HeaderVideo from "../../Componants/HeaderVideo";

const School = () => {
  document.title = lang.metaTitleSchool;
  const description1 = [
    lang.SchoolPresentation,
    lang.SchoolPresentation1,
    lang.SchoolPresentation2,
    lang.SchoolPresentation3,
  ] as Array<string>;
  const description2 = [
    lang.SchoolPresentationTeam,
    lang.SchoolPresentationTeam1,
    lang.SchoolPresentationTeam2,
  ] as Array<string>;
  const description3 = [
    lang.SchoolEnjoy,
    lang.SchoolEnjoy1,
    lang.SchoolEnjoy2,
  ] as Array<string>;
  return (
    <div>
      <HeaderVideo />
      <div className="school-section-1">
        <div>
          <CardImage descriptions={description1} image={school1} />
        </div>
      </div>
      <div className="school-section-1">
        <div>
          <CardImage
            descriptions={description2}
            image={school2}
            inverser={true}
          />
        </div>
      </div>
      <div className="school-section-1">
        <CardImage descriptions={description3} image={shcool3} />
      </div>

      <div>
        <ChartreElement />
      </div>
    </div>
  );
};

export default School;
