import React from "react";
import "./styles.css";
import bg from "../../Assets/images/contact.jpg";
import { sendEmail, templeteHtmlContact } from "../../Utils";
import lang from "../../Utils/lang";
import { Formik } from "formik";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ContactPage = () => {
  document.title = lang.metaTitleContact;
  const sendContact = (values, setSubmitting) => {
    const msg = templeteHtmlContact(values.message, values.email, values.phone);
    sendEmail(msg);
    setSubmitting(false);
  };

  const ValidateForm = (values) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.phone) {
      errors.phone = "Required";
    } else if (
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        values.phone
      )
    ) {
      errors.phone = "Invalid phone number";
    }
    if (!values.message.trim()) {
      errors.message = "Required";
    }
    return errors;
  };

  return (
    <div>
      <div className="section-contact">
        <div className="description-contact">
          <span className="title-contact">Contact</span>
          <span className="contact-decription">{lang.ContactPresentation}</span>
          <span className="contact-decription">{lang.adress}</span>
          <span className="contact-decription">{lang.zipcode}</span>
          <a
            href={`mailto:${lang.email}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            <span className="contact-decription">{lang.email}</span>
          </a>
          <a
            href={`tel:${lang.phoneNumber}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            <span className="contact-decription">{lang.phoneNumber}</span>
          </a>
        </div>
        <div className="section-form-contact">
          <Formik
            initialValues={{ email: "", phone: "", message: "" }}
            validate={(values) => ValidateForm(values)}
            onSubmit={(values, setSubmitting) =>
              sendContact(values, setSubmitting)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  required
                />
                {errors.email && touched.email && errors.email}
                <input
                  type="input"
                  name="phone"
                  placeholder={lang.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  required
                />
                {errors.phone && touched.phone && errors.phone}
                <textarea
                  name="message"
                  style={{ marginTop: "30px", padding: "6px", width: "100%" }}
                  placeholder={lang.messageInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  required
                ></textarea>
                {errors.message && touched.message && errors.message}
                <button
                  type="submit"
                  className="button-send-form"
                  disabled={isSubmitting}
                >
                  {lang.send}
                </button>
              </form>
            )}
          </Formik>
          {lang.thanks}
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        {/* <LazyLoadImage
          width={"100%"}
          height={"100%"}
          style={{ marginTop: "-10%" }}
          src={
            "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317178/samples/surf/contact_pypx8o.jpg"
          }
        /> */}
        <img className="contact-image-bottom" src={bg} />
      </div>
    </div>
  );
};

export default ContactPage;
