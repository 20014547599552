import React from "react";
import "./styles.css";
import { Button } from "react-bootstrap";
import { RedirectPage } from "../../Utils";
import lang from "../../Utils/lang";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface Props {
  title: string;
  subTitle?: string;
  descriptions: Array<string>;
  image?: any;
}

const CardElement = (props: Props) => {
  const { title, descriptions, image, subTitle } = props;

  const HandleBooking = () => {
    RedirectPage("Booking");
  };

  const getDescription = (description) => {
    return description ? (
      <span className="forfait-description">{description}</span>
    ) : null;
  };
  return (
    <div className="forfait-card">
      <div className="forfait-card-content">
        {/* <img className="forfait-card-image" src={image} /> */}
        <LazyLoadImage
          style={{ paddingBottom: "20px" }}
          width={"100%"}
          height={"100%"}
          src={image}
        />
        <div
          style={{
            bottom: 0,
            position: "absolute",
            width: "100%",
            height: "50%",
            background: "linear-gradient(to bottom , #00000000, #000)",
          }}
        />
        <div className="content-forfait-info">
          <div className="forfait-title">{title}</div>
          {subTitle && <div className="forfait-sub-title">{subTitle}</div>}
        </div>
      </div>

      {descriptions.map((description) => getDescription(description))}
      <div className="container-button-resa">
        <Button
          onClick={() => HandleBooking()}
          className="reservation-button"
          variant="primary"
        >
          {lang.BookingButton}
        </Button>
      </div>
    </div>
  );
};

export default CardElement;
