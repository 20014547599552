import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./Screens/Home";
import ContactPage from "./Screens/Contact";
import ErrorPage from "./Screens/Error";
import BottomElement from "./Componants/BottomElement";
import Packages from "./Screens/Packages";
import Topbar from "./Componants/Topbar";
import Galerie from "./Screens/Galerie";
import School from "./Screens/School";
import Booking from "./Screens/Booking";
require("dotenv").config();
const App: React.FC = () => {
  return (
    <div className="App">
      <Topbar />
      <div id="content-body" className="content">
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route
              path="/Ecole-de-surf-Longeville-sur-mer"
              component={School}
            />
            <Route
              path="/Tarif-ecole-de-surf-Vendee-Longeville"
              component={Packages}
            />
            <Route path="/Booking" component={Booking} />
            <Route path="/Contact" component={ContactPage} />
            <Route path="/Gallery" component={Galerie} />
            <Route component={ErrorPage} />
          </Switch>
        </Router>
      </div>
      <BottomElement />
    </div>
  );
};

export default App;
