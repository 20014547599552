import "./styles.css";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
const HeaderVideo = () => {
  const [play, setPlay] = useState<boolean>(true);
  const [mute, setMute] = useState<boolean>(true);
  let ref = useRef(null);

  //@ts-ignore
  const reloadVideo = () => ref.seekTo(0);

  const playVideo = () => {
    setPlay(!play);
  };

  return (
    <div className={"header-video-container"}>
      <div className={"div-sticky-class"}>
        <button
          onClick={() => setMute(!mute)}
          className={`header-video-button ${mute ? "mute" : "sound"}`}
        ></button>
        <button
          onClick={() => playVideo()}
          className={`header-video-button ${play ? "pause" : "play"}`}
        ></button>

        <button
          onClick={() => {
            reloadVideo();
            setPlay(true);
          }}
          className={"header-video-button replay"}
        ></button>
      </div>
      <ReactPlayer
        ref={(refer) => {
          //@ts-ignore
          ref = refer;
        }}
        width="100%"
        height="100%"
        muted={mute}
        playing={play}
        className={"container-video"}
        url={process.env.REACT_APP_VIDEO}
      />
    </div>
  );
};
export default HeaderVideo;
