import React from "react";
import PartenairesElement from "../../Componants/PartenairesElement";
import "./styles.css";
import TopBackground from "../../Componants/TopBackground";
import lang from "../../Utils/lang";
import ChartreElement from "../../Componants/ChartreElement";

const HomePage = () => {
  document.title = lang.metaTitleHome;
  return (
    <div>
      <TopBackground />
      <div className="contentStyleHomeDescription">
        <span className="contentStyleHomeDescriptionText">
          {lang.HomeText1}
        </span>
        <span className="contentStyleHomeDescriptionText">
          {lang.HomeText2}
        </span>
        <span className="contentStyleHomeDescriptionText">
          {lang.HomeText3}
        </span>
      </div>
      <ChartreElement />
      <PartenairesElement />
    </div>
  );
};
export default HomePage;
