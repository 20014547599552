import React from "react";
import bg from "../../Assets/images/cours.jpg";
import decouvertImage from "../../Assets/images/decouvert.jpg";
import stageImage from "../../Assets/images/stage.jpg";
import TeensImage from "../../Assets/images/teensB.jpg";
import kids from "../../Assets/images/kids.jpg";
import mini from "../../Assets/images/mini.jpg";
import perfImage from "../../Assets/images/perf.jpg";
import planClub from "../../Assets/images/club.png";
import planEte from "../../Assets/images/ete.png";
import adultImage from "../../Assets/images/adultsB.jpg";
import "./styles.css";
import CardElement from "../../Componants/CardElement";
import lang from "../../Utils/lang";
import ChartreElement from "../../Componants/ChartreElement";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Packages = () => {
  document.title = lang.metaTitlePackage;
  const PlanFormule1 = [lang.PlanFormule1] as Array<string>;
  const PlanFormule2 = [
    lang.PlanFormule2,
    lang.PlanFormule21,
    lang.PlanFormule22,
  ] as Array<string>;
  const PlanFormuleClub1 = [
    lang.PlanFormuleClub11,
    lang.PlanFormuleClub12,
  ] as Array<string>;
  const PlanFormuleClub2 = [lang.PlanFormuleClub21] as Array<string>;
  const PlanFormuleClub3 = [lang.PlanFormuleClub31] as Array<string>;
  const PlanFormuleClub4 = [lang.PlanFormuleClub41] as Array<string>;
  const PlanFormuleClub5 = [lang.PlanFormuleClub51] as Array<string>;

  return (
    <div>
      <div className="container-forfait-bg">
        {/* <LazyLoadImage
          style={{ marginTop: "-20%", marginBottom: "-10%" }}
          width={"100%"}
          height={"100%"}
          src={
            "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317552/samples/surf/cours_ugjmrs.jpg"
          }
        /> */}
        <img
          style={{ marginTop: "-20%", marginBottom: "-10%" }}
          className="bg-forfait"
          src={bg}
        />
      </div>
      <div className="section-forfait-ete">
        <span
          style={{ fontWeight: "bold", fontSize: "40px", color: "#474747" }}
        >
          {lang.PlanClub}
        </span>
        <div className="forfait-content">
          <CardElement
            title={lang.PlanDiscover}
            subTitle={lang.price1}
            descriptions={PlanFormule1}
            image={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317507/samples/surf/decouvert_uxgbz3.jpg"
            }
          />
          <CardElement
            title={lang.PlanCours}
            descriptions={PlanFormule2}
            subTitle={lang.price2}
            image={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317545/samples/surf/stage_qkcyqa.jpg"
            }
          ></CardElement>
        </div>
      </div>
      <div className="section-price-packages">
        <LazyLoadImage width={"100%"} height={"100%"} src={planEte} />
        {/* <img width="100%" src={planEte} /> */}
      </div>
      <div className="section-forfait-club">
        <span
          style={{
            fontSize: "40px",
            paddingBottom: "20px",
            fontWeight: "bold",
            color: "#474747",
          }}
        >
          {lang.ClubPrograms}
        </span>
        <span className="forfait-club-description">
          {lang.ClubProgramsDescriptionl1}
        </span>
        <span className="forfait-club-description">
          {lang.ClubProgramsDescriptionl2}
        </span>
        <span className="forfait-club-description">
          {lang.ClubProgramsDescriptionl3}
        </span>
        <div
          className="forfait-content"
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <CardElement
            title={lang.PlanFormuleClub2Title}
            subTitle={lang.subTitleClub2}
            image={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317665/samples/surf/kids_btlaco.jpg"
            }
            descriptions={PlanFormuleClub2}
          />
          <CardElement
            title={lang.PlanFormuleClub4Title}
            image={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317783/samples/surf/teensB_n8pbhk.jpg"
            }
            descriptions={PlanFormuleClub3}
          />
        </div>
        <div
          className="forfait-content-1"
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <CardElement
            title={lang.PlanFormuleClub5Title}
            image={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317719/samples/surf/perf_ejowx4.jpg"
            }
            descriptions={PlanFormuleClub4}
          />
          <CardElement
            title={lang.PlanFormuleClub6Title}
            image={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613317539/samples/surf/adultsB_uy8xwb.jpg"
            }
            descriptions={PlanFormuleClub5}
          />
        </div>
      </div>
      <div className="section-price-packages">
        <LazyLoadImage width={"100%"} height={"100%"} src={planClub} />
        {/* <img width="100%" src={planClub} /> */}
      </div>
      <div>
        <ChartreElement />
      </div>
    </div>
  );
};

export default Packages;
