import React from "react";
import SocialElement from "../SocialElement";
import "./styles.css";
import lang from "../../Utils/lang";
const BottomElement = () => {
  return (
    <div>
      <div className="container-bottom-bar">
        <div>
          <span style={{ fontSize: 20, fontWeight: "bold" }}>
            {lang.contact}
          </span>
          <li>{lang.adress}</li>
          <li>{lang.zipcode}</li>
          <li>
            <a
              href={`mailto:${lang.email}`}
              style={{ textDecoration: "none", color: "white" }}
            >
              {lang.email}
            </a>
          </li>
          <li>
            <a
              href={`tel:${lang.phoneNumber}`}
              style={{ textDecoration: "none", color: "white" }}
            >
              {lang.phoneNumber}
            </a>
          </li>
        </div>

        <div>
          <span style={{ fontSize: 20, fontWeight: "bold" }}>
            {lang.follow}
          </span>
          <SocialElement
            icon={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613316853/samples/surf/insta_caeqf4.png"
            }
            socialName="INSTAGRAM"
            link="https://instagram.com/labluesurfschool"
          />
          <SocialElement
            icon={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613316846/samples/surf/fb_gvwnml.png"
            }
            socialName="FACEBOOK"
            link="https://www.facebook.com/Blue-Surf-School-100850611510845/"
          />
          <SocialElement
            icon={
              "https://res.cloudinary.com/dvixkahgx/image/upload/v1613316859/samples/surf/whatsapp_fapy3k.png"
            }
            socialName="WHATSAPP"
            link="https://wa.me/33611462582"
          />
        </div>
      </div>
      <div className={"generaleCondition"}>
        <a
          href={require("../../Assets/downloadFile/ConditionsG.pdf")}
          target="_blank"
          style={{ textDecoration: "none", color: "#999999" }}
        >
          Conditions générales
        </a>
      </div>
    </div>
  );
};

export default BottomElement;
