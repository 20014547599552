import React, { useState } from "react";
import "./styles.css";
import * as EmailValidator from "email-validator";
import { templeteHtmlDemande, sendEmail } from "../../Utils";
import lang from "../../Utils/lang";
import { Formik } from "formik";

const FormElement = () => {
  const sendBooking = (values, setSubmitting) => {
    const message = templeteHtmlDemande(
      values.firstName,
      values.name,
      values.request,
      values.mail,
      values.numberPhone
    );
    sendEmail(message);
    setSubmitting(false);
  };

  // const check = (mail, numberPhone, request, firstName, name) => {
  //   var reg = new RegExp("^0[1-9]([-. ]?[0-9]{2}){4}$");
  //   if (firstName === undefined || firstName.trim() === "") {
  //     error("firstName");
  //     return false;
  //   }
  //   defaultValue("firstName");
  //   if (name === undefined || name.trim() === "") {
  //     error("name");
  //     return false;
  //   }
  //   defaultValue("name");
  //   if (
  //     !EmailValidator.validate(mail) ||
  //     mail === undefined ||
  //     mail.trim() === ""
  //   ) {
  //     error("mail");
  //     return false;
  //   }
  //   defaultValue("mail");
  //   if (
  //     !reg.test(numberPhone) ||
  //     numberPhone === undefined ||
  //     numberPhone.trim() === ""
  //   ) {
  //     error("numberPhone");
  //     return false;
  //   }
  //   defaultValue("numberPhone");
  //   if (request === undefined || request.trim() === "") {
  //     error("request");
  //     return false;
  //   }
  //   defaultValue("request");
  //   return true;
  // };

  // const defaultValue = id => {
  //   const element: any = document.getElementById(id);
  //   element.style.borderColor = "";
  // };

  // const error = id => {
  //   const element: any = document.getElementById(id);
  //   element.style.borderColor = "red";
  // };

  const ValidateForm = values => {
    const errors: any = {};
  };

  return (
    <div className="form-contact">
      <Formik
        initialValues={{
          firstName: "",
          name: "",
          mail: "",
          numberPhone: "",
          request: ""
        }}
        validate={values => ValidateForm(values)}
        onSubmit={(values, setSubmitting) => sendBooking(values, setSubmitting)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              id="firstName"
              placeholder={lang.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              required
            />
            {errors.firstName && touched.firstName && errors.firstName}
            <input
              id="name"
              placeholder={lang.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
            />
            {errors.name && touched.name && errors.name}
            <input
              id="mail"
              placeholder="E-mail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mail}
              required
            />
            {errors.mail && touched.mail && errors.mail}
            <input
              type="number"
              id="numberPhone"
              placeholder={lang.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.numberPhone}
              required
            />
            {errors.numberPhone && touched.numberPhone && errors.numberPhone}
            <textarea
              id="request"
              style={{
                marginTop: "30px",
                paddingLeft: "5px",
                width: "100%",
                height: "100px"
              }}
              placeholder={lang.messageInput}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.request}
              required
            />
            {errors.request && touched.request && errors.request}
            <button
              type="submit"
              className="button-send-form"
              disabled={isSubmitting}
            >
              {lang.send}
            </button>
          </form>
        )}
      </Formik>
      {lang.thanks}
    </div>
  );
};

export default FormElement;
