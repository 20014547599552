import React from "react";
import "./styles.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface Props {
  descriptions: Array<string>;
  image: any;
  inverser?: boolean;
}

const CardImage = (props: Props) => {
  const { descriptions, image, inverser } = props;

  const monStyle: string = inverser ? "card-image-reverse" : "card-image";
  const getDescription = (des) => {
    return des ? <span>{des}</span> : null;
  };
  return (
    <div className={monStyle}>
      <div className="card-image-text">
        {descriptions.map((description) => getDescription(description))}
      </div>
      <div className="card-image-img">
        <LazyLoadImage width={"100%"} src={image} />
        {/* <img src={image} /> */}
      </div>
    </div>
  );
};
export default CardImage;
