import React, { useState, useEffect } from "react";
import "./styles.css";
import BicSurf from "../../Assets/images/BicSurf.png";
import GarddiSarl from "../../Assets/images/GarddiSarl.jpg";
import EcoSafe from "../../Assets/images/EcoSafe.png";
import RipCurlWetsuits from "../../Assets/images/RipCurlWetsuits.png";
import zeussurfboards from "../../Assets/images/Zeuslogo.png";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import lang from "../../Utils/lang";
import { LazyLoadImage } from "react-lazy-load-image-component";

const offset = 400;
const PartenairesElement = () => {
  const listCard = [] as any;

  listCard.push({
    url: "https://www.ripcurl.eu/fr/mens/wetsuits.html",
    img: RipCurlWetsuits,
  });
  listCard.push({
    url: "https://zeussurfboards.com",
    img: zeussurfboards,
  });
  listCard.push({ url: "https://www.bicsport.fr/surf.html", img: BicSurf });
  listCard.push({ url: "https://www.gariddicouverture.fr", img: GarddiSarl });
  listCard.push({ url: "https://www.ecosafesurfing.com", img: EcoSafe });
  const [slidesPerPage, setSlidesPerPage] = useState(
    (window.innerWidth * 3) / 4 / offset
  );

  const handlScroll = () => {
    const slideP = (window.innerWidth * 3) / 4 / offset;
    setSlidesPerPage(slideP);
  };

  const removeListener = () => {
    window.removeEventListener("resize", handlScroll);
  };
  const addListener = () => {
    window.addEventListener("resize", handlScroll);
  };

  useEffect(() => addListener());
  useEffect(() => {
    return () => {
      removeListener();
    };
  }, []);

  return listCard.length > 0 ? (
    <div className="section-partenaire">
      <span className="title-partenaires">{lang.partners}</span>

      <Carousel
        slidesPerPage={slidesPerPage <= 3 ? slidesPerPage : 3}
        infinite
        animationSpeed={1500}
        autoPlay={3500}
        stopAutoPlayOnHover
        centered
      >
        {listCard.map((card) => (
          <a href={card.url} target="_blank">
            {/* <img width="200" src={card.img} /> */}
            <LazyLoadImage width={"200"} src={card.img} />
          </a>
        ))}
      </Carousel>
    </div>
  ) : null;
};

export default PartenairesElement;
