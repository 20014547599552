import React, { useEffect } from "react";
import "./styles.css";
import lang from "../../Utils/lang";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";

let listImg = [] as any;

const Galerie = () => {
  document.title = lang.metaTitleGalerie;

  const importAll = (r) => {
    return r.keys().map(r);
  };

  // const instagramPhotos = async () => {
  //   // It will contain our photos' links
  //   const res = [] as any;
  //   axios.get("https://www.instagram.com/theraloss/").then(function (result) {
  //     console.log("result axio", result.data);
  //   });
  // };
  // useEffect(() => {
  //   instagramPhotos();
  // }, []);

  const load = () => {
    listImg = importAll(
      require.context("../../Assets/gallery/", false, /\.(png|jpg?g|svg|JPG)$/)
    );

    return (
      <div>
        {listImg.length <= 0 && (
          <div style={{ height: "200px", width: "100%" }}>
            <span>{lang.GalleryNotImages}</span>
          </div>
        )}
        {listImg.length > 0 && (
          <div className="content-galerie">
            {listImg.map((image, index) => (
              <div className="image-card">
                <LazyLoadImage key={index} src={image} />
                {/* <img key={index} src={image}></img> */}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return <div className="container-galerie">{load()}</div>;
};

export default Galerie;
